import { css, cx } from '@emotion/css';
import { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';
import config from 'app/core/config';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

//{*NS*}//
let loginpic: string;
let focusname: string;
if (config.NSbranding === 'TRS') {
  loginpic = 'public/img/TRSLogin.svg';
  focusname = 'Target Reservoir Solutions';
} else if (config.NSbranding === 'SF') {
  loginpic = 'public/img/ShearFracLogin.png';
  focusname = 'ShearFrac';
} else {
  loginpic = 'public/img/NewscadaWhite.svg';
  focusname = 'NewSCADA';
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src={loginpic} alt={focusname} />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme2();

  const background = css({
    '&:before': {
      content: '""',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      background: `url(public/img/g8_login_${theme.isDark ? 'dark' : 'light'}.svg)`,
      backgroundPosition: 'top center',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',

      opacity: 0,
      transition: 'opacity 3s ease-in-out',

      [theme.breakpoints.up('md')]: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
    },
  });

  return <div className={cx(background, className)}>{children}</div>;
};

//{*NS*}//
const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  if (config.NSbranding === 'TRS') {
    focusname = 'Target Reservoir Solutions';
  } else if (config.NSbranding === 'SF') {
    focusname = 'ShearFrac';
  } else {
    focusname = 'NewSCADA';
  }
  return <img className={className} src="public/img/NewscadaSidebar.svg" alt={focusname} />;
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css({
    background: colorManipulator.alpha(theme.colors.background.primary, 0.7),
    backgroundSize: 'cover',
  });
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = focusname;
  static LoginTitle = 'Welcome to ' + focusname;
  static LoginSubTitle = config.ClientName;
}
